import { createSelector } from '@reduxjs/toolkit';

import { Namespaces } from '@taxfix/it-xpath-schema';
import { FieldsAPI as SpanishFields } from '@taxfix/spain-xpath-schema/dist/fields-api';
import { mergeTaxEngineData } from '@taxfix/submission-fields';
import {
  GroupOutput as GroupOutputIT,
  ItalianFields,
} from '@taxfix/submission/browser';
import {
  AppDataQuizMaster,
  DraftReasons,
  SpainAppDataQuizMaster,
  States,
  SubmissionTypesIds,
} from '@taxfix/submissions-types';
import { Fields as GermanFields } from '@taxfix/tax-authority-de-sdk';
import {
  FieldsList,
  GroupOutput as GroupOutputDE,
} from '@taxfix/tax-authority-de-types';
import {
  CountryCodes,
  Documents,
  SystemVersion,
  TaxEngine,
  Years,
} from '@taxfix/types';

import { intlStringsRegistry } from '../../intl';
import { calculateIdentified } from '../../utils/identification-states';
import {
  FieldGroupCustom,
  PlausibilityOutput,
  SpanishFieldGroup,
  SubmissionSection,
  SubmissionState,
  ValidationOutput,
} from './types';
import {
  enrichAdditionalFields,
  enrichAdditionalFieldsIt,
  fieldGroupToSectionData,
  fieldGroupToSectionDataES,
  getJobTitles,
  isJointAssessment,
} from './utils';

export const submissionSelector = ({
  submission,
}: {
  submission: SubmissionState;
}): SubmissionState => submission;

export const submissionErrorSelector = createSelector(
  submissionSelector,
  ({ error }) => error,
);

export const submissionLoadingSelector = createSelector(
  submissionSelector,
  ({ loading }) => loading,
);

export const submissionUploadingSelector = createSelector(
  submissionSelector,
  ({ uploadingDocument }) => uploadingDocument,
);

export const submissionModifiedSelector = createSelector(
  submissionSelector,
  ({ modified }) => modified,
);

export const submissionUserIdSelector = createSelector(
  submissionSelector,
  ({ userId }) => userId,
);

export const isJointAssessmentSelector = createSelector(
  submissionSelector,
  ({ data }) => isJointAssessment(data as TaxEngine.TaxForm.Field[]),
);

export const jobTitlesSelector = createSelector(
  submissionSelector,
  ({ data }) => getJobTitles(data as TaxEngine.TaxForm.Field[]),
);

export const submissionYearSelector = createSelector(
  submissionSelector,
  ({ year }) => year,
);

const submissionCountrySelector = createSelector(
  submissionSelector,
  ({ countryCode }) => countryCode,
);

// Workaround - There is no more quizmaster 2023 and tax-authority-de-sdk is not prepared for 2023
export const submissionYearSupportedByFieldsAPISelector = createSelector(
  submissionCountrySelector,
  submissionYearSelector,
  (countryCode, year) =>
    countryCode === CountryCodes.DE && year > Years['2k22']
      ? Years['2k22']
      : year,
);

export const submissionArchivedSelector = createSelector(
  submissionSelector,
  ({ archivedAt }) => archivedAt,
);

export const submissionIdSelector = createSelector(
  submissionSelector,
  ({ id }) => id,
);

export const submissionInfoSelector = createSelector(
  submissionSelector,
  isJointAssessmentSelector,
  jobTitlesSelector,
  (
    {
      id,
      userId,
      state,
      year,
      type,
      countryCode,
      paymentId,
      paymentStatus,
      identificationDocumentId,
      identificationSource,
      identificationDocumentState,
      systemVersion,
      isAutomatedSubmission,
      isPrefilled,
      isReviewRequired,
    },
    isJointAssessment,
    jobTitles,
  ) => ({
    id,
    userId,
    state,
    year,
    type,
    countryCode,
    paymentId,
    paymentStatus,
    isJointAssessment,
    jobTitles,
    identificationDocumentId,
    identificationSource,
    identificationDocumentState,
    systemVersion,
    isAutomatedSubmission,
    isPrefilled,
    isReviewRequired,
  }),
);

export const submissionDatesInfoSelector = createSelector(
  submissionSelector,
  ({ created, deleted, updated, submittedAt }) => ({
    created,
    deleted,
    updated,
    submittedAt,
  }),
);

export const submissionResponsesSelector = createSelector(
  submissionSelector,
  ({ appData }) => appData,
);

export const submissionQMResponsesSelector = createSelector(
  submissionResponsesSelector,
  responses => Object.values(responses as AppDataQuizMaster),
);

export const submissionSpainQMResponsesSelector = createSelector(
  submissionResponsesSelector,
  responses =>
    responses ? Object.values(responses as SpainAppDataQuizMaster) : [],
);

export const submissionTEResponsesSelector = createSelector(
  submissionResponsesSelector,
  responses => (responses ? Object.values(responses) : []),
);

export const submissionQMResponsesForYearSelector = createSelector(
  submissionQMResponsesSelector,
  submissionYearSelector,
  (responses, year) =>
    responses.filter(({ year: responseYear }) => Number(responseYear) === year),
);

const submissionRawDataSelector = createSelector(
  submissionSelector,
  ({ data }) => data,
);

export const submissionDataSelector = createSelector(
  submissionRawDataSelector,
  data =>
    (data as TaxEngine.TaxForm.Field[]).map((field, index) => ({
      ...field,
      originalIndex: index,
    })),
);

export const submissionValidationErrorsSelector = createSelector(
  submissionSelector,
  ({ validationOutput }) => validationOutput as ValidationOutput | string,
);

export const submissionPlausibilityErrorsSelector = createSelector(
  submissionSelector,
  ({ plausibilityOutput }) => plausibilityOutput as PlausibilityOutput,
);

export const submissionFailedReasonSelector = createSelector(
  submissionSelector,
  ({ failedReason }) => failedReason as string,
);

export const submissionCountryCodeSelector = createSelector(
  submissionSelector,
  ({ countryCode }) => countryCode.toUpperCase() as CountryCodes,
);

export const submissionNotesSelector = createSelector(
  submissionSelector,
  ({ notes }) => notes,
);

const fieldsAPISelector = createSelector(
  submissionCountryCodeSelector,
  countryCode => {
    // Use `fieldsCreator(countryCode)` to support all countries
    // This is an optimization to avoid including logic from unused countries
    switch (countryCode) {
      case CountryCodes.DE:
        return new GermanFields();
      case CountryCodes.IT:
        return new ItalianFields();
      case CountryCodes.ES:
        return new SpanishFields();
      default:
        // avoid undefined returns
        return new GermanFields();
    }
  },
);

export const submissionFieldsSelector = createSelector(
  submissionYearSupportedByFieldsAPISelector,
  fieldsAPISelector,
  (year, fieldsAPI) => fieldsAPI.getList({ year }) as FieldsList,
);

export const submissionFieldsSelectorIt = createSelector(
  submissionYearSupportedByFieldsAPISelector,
  fieldsAPISelector,
  (year, fieldsAPI) =>
    fieldsAPI.getList({ year, type: Namespaces.m730 }) as FieldsList,
);

export const submissionOverridesSelector = createSelector(
  submissionSelector,
  ({ overrides: { overrides } }) => overrides,
);

export const submissionFullOverridesSelector = createSelector(
  submissionSelector,
  ({ overrides }) => overrides,
);

export const submissionAdditionalOverridesSelector = createSelector(
  submissionSelector,
  ({ overrides }) => overrides.additionalOverrides,
);

export const submissionIsOriginalSelector = createSelector(
  submissionOverridesSelector,
  submissionAdditionalOverridesSelector,
  (overrides, additionalOverrides) =>
    Boolean(overrides?.length || additionalOverrides?.length),
);

export const submissionAdditionalFieldsSelector = createSelector(
  submissionFullOverridesSelector,
  submissionYearSupportedByFieldsAPISelector,
  fieldsAPISelector,
  submissionCountryCodeSelector,
  (overrides, year, fieldsAPI, countryCode) => {
    const rawAdditionalFields = overrides.additionalFields.map(
      (field, index) => ({
        ...field,
        originalIndex: index,
        isAdditionalField: true,
      }),
    );

    // TODO: Feature in progress, since spain is using additionalFields for validations
    // is breaking this, im desabling it to avoid breaking the page, but needs to be fixed later
    //TODO: This is also breaking for Netherlands probably for the same reason.
    if (countryCode === CountryCodes.ES || countryCode === CountryCodes.NL) {
      return rawAdditionalFields;
    }
    if (countryCode === CountryCodes.IT) {
      return enrichAdditionalFieldsIt(
        rawAdditionalFields,
        fieldsAPI.group({ fields: rawAdditionalFields, year }) as GroupOutputIT,
      );
    }
    return enrichAdditionalFields(
      rawAdditionalFields,
      fieldsAPI.group({ fields: rawAdditionalFields, year }) as GroupOutputDE,
    );
  },
);

export const submissionGroupedFieldsSelector = createSelector(
  submissionDataSelector,
  submissionYearSupportedByFieldsAPISelector,
  fieldsAPISelector,
  (data, year, fieldsAPI) =>
    fieldsAPI.group({
      fields: data,
      year,
    }) as SubmissionSection[],
);

export const submissionWithAdditionalsGroupedFieldsSelector = createSelector(
  submissionDataSelector,
  submissionAdditionalFieldsSelector,
  submissionYearSupportedByFieldsAPISelector,
  fieldsAPISelector,
  (data, additionalFields, year, fieldsAPI) =>
    fieldsAPI.group({
      fields: data.concat(additionalFields),
      year,
    }) as SubmissionSection[],
);

export const fieldsInSubmissionSelector = createSelector(
  submissionGroupedFieldsSelector,
  submissionAdditionalFieldsSelector,
  (groupedFields, additionalFields) => {
    return [
      ...groupedFields,
      {
        isAdditionalField: true,
        title: intlStringsRegistry.getMessage(
          'de.submission.additional_fields',
        ),
        fields: additionalFields,
      },
    ];
  },
);

export const fieldsInSubmissionWithAdditionalsSelector = createSelector(
  submissionWithAdditionalsGroupedFieldsSelector,
  groupedFields => [...groupedFields],
);

export const submissionSystemVersionSelector = createSelector(
  submissionSelector,
  ({ systemVersion }) => systemVersion,
);

export const submissionDocumentIdSelector = createSelector(
  submissionSelector,
  ({ identificationDocumentId }) => identificationDocumentId,
);

export const submissionUnifiedIdentificationSelector = createSelector(
  submissionSelector,
  submission => calculateIdentified(submission),
);

export const submissionDocumentStateSelector = createSelector(
  submissionSelector,
  ({ identificationDocumentState }) => identificationDocumentState,
);

export const submissionStateSelector = createSelector(
  submissionSelector,
  ({ state }) => state,
);

export const submissionIsDraftSelector = createSelector(
  submissionSelector,
  ({ type }) => type === SubmissionTypesIds.Draft,
);

export const submissionIsUnderExpertReviewSelector = createSelector(
  submissionSelector,
  ({ type, metadata }) =>
    Boolean(
      type === SubmissionTypesIds.Draft &&
        metadata?.draftReason?.includes(DraftReasons.ExpertReview),
    ),
);

export const submissionIsUnderDifmSelector = createSelector(
  submissionSelector,
  ({ metadata }): Boolean =>
    Boolean(metadata?.draftReason?.includes(DraftReasons.DoItForMe)),
);

export const submissionITCVersionSelector = createSelector(
  submissionSelector,
  ({ metadata }): string | undefined => metadata?.incomeTaxCalculatorVersion,
);

export const submissionIsDigitalPrefillSelector = createSelector(
  submissionSelector,
  ({ metadata }): boolean | undefined => metadata?.isVDBPrefill,
);

export const submissionEnableModifySelector = createSelector(
  submissionSelector,
  ({ state, systemVersion }) => {
    return (
      ![States.Submitted, States.Deleted].includes(state) &&
      systemVersion !== SystemVersion.TaxCore
    );
  },
);

export const submissionResultsSelector = createSelector(
  submissionSelector,
  ({ results }) => results || [],
);

export const submissionResultPdfSelector = createSelector(
  submissionResultsSelector,
  results => {
    return results ? results[0].pdf : undefined;
  },
);

export const isSubmissionDocumentApprovedSelector = createSelector(
  submissionUnifiedIdentificationSelector,
  submissionUnifiedIdentification =>
    submissionUnifiedIdentification.state === Documents.States.Approved,
);

export const submissionDetailsSectionsSelector = createSelector(
  [
    submissionCountryCodeSelector,
    submissionWithAdditionalsGroupedFieldsSelector,
    submissionOverridesSelector,
    submissionAdditionalOverridesSelector,
  ],
  (countryCode, fieldsWithAdditions, overrides, additionalOverrides) => {
    if (countryCode === CountryCodes.ES) {
      return fieldsWithAdditions.map(group =>
        fieldGroupToSectionDataES(
          group as SpanishFieldGroup,
          overrides,
          additionalOverrides,
        ),
      );
    }
    return fieldsWithAdditions.map((group: FieldGroupCustom) =>
      fieldGroupToSectionData(group, overrides, additionalOverrides),
    );
  },
);

export const submissionPendingValidationSelector = createSelector(
  [submissionStateSelector, submissionCountryCodeSelector],
  (state, countryCode) =>
    countryCode === CountryCodes.ES
      ? false
      : [States.Pending, States.Created].includes(Number(state)),
);

export const submissionUpdateRetriesSelector = (state: {
  submission: SubmissionState;
}): number => state.submission.retries;

export const submissionDeviationSelector = createSelector(
  submissionSelector,
  ({ deviation }) => deviation,
);

export const submissionGroupedFieldsWithOverrides = createSelector(
  submissionDataSelector,
  submissionYearSupportedByFieldsAPISelector,
  fieldsAPISelector,
  submissionOverridesSelector,
  submissionAdditionalFieldsSelector,
  submissionAdditionalOverridesSelector,
  (data, year, fieldsAPI, overrides, additionalFields, additionalOverrides) => {
    const mergedFields = mergeTaxEngineData(data, {
      overrides,
      additionalOverrides,
      additionalFields,
    });

    return fieldsAPI.group({
      fields: mergedFields,
      year,
    });
  },
);

export const submissionFieldsWithOverridesIt = createSelector(
  submissionDataSelector,
  submissionOverridesSelector,
  submissionAdditionalFieldsSelector,
  submissionAdditionalOverridesSelector,
  (data, overrides, additionalFields, additionalOverrides) => {
    const mergedFields = mergeTaxEngineData(data, {
      overrides,
      additionalOverrides,
      additionalFields,
    });

    return mergedFields;
  },
);

export const submissionsImportingSelector = createSelector(
  submissionSelector,
  ({ importing }) => importing,
);

export const spainSelector = createSelector(
  submissionSelector,
  ({ spain }) => spain,
);

export const submissionSpainLoading = createSelector(
  spainSelector,
  ({ submissionLoading }) => submissionLoading,
);

export const exportSpainXMLSubmissionSelector = createSelector(
  spainSelector,
  ({ xmlSubmission, exportXMLLoading }) => ({
    xmlSubmission,
    loading: exportXMLLoading,
  }),
);

export const importSpainXMLSubmissionSelector = createSelector(
  spainSelector,
  ({ importXMLLoading }) => ({
    loading: importXMLLoading,
  }),
);

export const importSpainSubmissionPdfSelector = createSelector(
  spainSelector,
  ({ importPDFLoading }) => ({
    loading: importPDFLoading,
  }),
);

export const spainPrefillSelector = createSelector(
  spainSelector,
  ({ prefill, isDownloadPrefillLoading }) => ({
    prefill,
    loading: isDownloadPrefillLoading,
  }),
);

export const spainDraftSelector = createSelector(
  spainSelector,
  ({ haciendaDraft, isDraftLoading }) => ({
    haciendaDraft,
    loading: isDraftLoading,
  }),
);

export const spainExpertReviewStatusSelector = createSelector(
  spainSelector,
  ({ expertReviewStatus, isExpertReviewStatusLoading }) => ({
    expertReviewStatus,
    isLoading: isExpertReviewStatusLoading,
  }),
);

export const spainReferenceNumberSelector = createSelector(
  spainSelector,
  ({ refNum, isUserDetailsRequestLoading }) => ({
    refNum,
    isLoading: isUserDetailsRequestLoading,
  }),
);

export const spainExpertReviewStatusUpdateSelector = createSelector(
  spainSelector,
  ({ isUpdatingExpertReviewStatusLoading }) => ({
    isLoading: isUpdatingExpertReviewStatusLoading,
  }),
);

export const submissionSpainDeclarationPdfSelector = createSelector(
  spainSelector,
  ({ declarationPdf, isDeclarationPdfLoading }) => ({
    declarationPdf,
    loading: isDeclarationPdfLoading,
  }),
);

export const submissionSpainM100PreviewSelector = createSelector(
  spainSelector,
  ({ m100Preview, isM100PreviewLoading }) => ({
    m100Preview,
    loading: isM100PreviewLoading,
  }),
);

export const spainPrefillAnswersSelector = createSelector(
  spainSelector,
  ({ prefillAnswers, isPrefillAnswersLoading }) => ({
    prefillAnswers,
    loading: isPrefillAnswersLoading,
  }),
);

export const submissionServiceTypeSelector = createSelector(
  submissionSelector,
  ({ serviceType }) => serviceType,
);

export const spainPrefillsDataSelector = createSelector(
  spainSelector,
  ({ prefillData, isPrefillDataLoading }) => ({
    prefillData,
    loading: isPrefillDataLoading,
  }),
);

export const netherlandsSelector = createSelector(
  submissionSelector,
  ({ netherlands }) => netherlands,
);

export const netherlandsExpertReviewStatusSelector = createSelector(
  netherlandsSelector,
  ({ expertReviewStatus, isExpertReviewStatusLoading }) => ({
    expertReviewStatus,
    isLoading: isExpertReviewStatusLoading,
  }),
);

export const netherlandsExpertReviewRefundAmountSelector = createSelector(
  netherlandsSelector,
  ({ expertReviewRefundAmount, isExpertReviewRefundAmountLoading }) => ({
    expertReviewRefundAmount,
    isLoading: isExpertReviewRefundAmountLoading,
  }),
);

export const netherlandsUserDetailSelector = createSelector(
  netherlandsSelector,
  ({
    bsn,
    userDetails,
    partnerDetails,
    rejection,
    prefillMethod,
    partnerACode,
    partnerBCode,
    isUserDetailsRequestLoading,
  }) => ({
    bsn,
    userDetails,
    partnerDetails,
    rejection,
    prefillMethod,
    partnerACode,
    partnerBCode,
    isLoading: isUserDetailsRequestLoading,
  }),
);

export const netherlandsExpertReviewStatusUpdateSelector = createSelector(
  netherlandsSelector,
  ({ isUpdatingExpertReviewStatusLoading }) => ({
    isLoading: isUpdatingExpertReviewStatusLoading,
  }),
);

export const importNLPdfSubmissionSelector = createSelector(
  netherlandsSelector,
  ({ importPDFLoading }) => ({
    loading: importPDFLoading,
  }),
);
export const netherlandsUserCategoryStateSelector = createSelector(
  netherlandsSelector,
  ({ categoryState, isCategoryStateRequestLoading }) => ({
    isLoading: isCategoryStateRequestLoading,
    categoryState,
  }),
);

export const netherlandsUserCategoryStateSelectorUpdateSelector =
  createSelector(netherlandsSelector, ({ isUpdatingCategoryState }) => ({
    updateInProgress: isUpdatingCategoryState,
  }));

export const submissionDigitalPrefillSelector = createSelector(
  submissionSelector,
  ({ digitalPrefill }) => digitalPrefill,
);

export const submissionIsFraudSelector = createSelector(
  submissionSelector,
  ({ labelledAsFraud }) => labelledAsFraud,
);
