import { PayloadAction, createAction, createSlice } from '@reduxjs/toolkit';

import { DoItForMeStatus } from '@taxfix/do-it-for-me-sdk/dist/types';
import { CountryCodes, Years } from '@taxfix/types';

import { intlStringsRegistry } from '../../intl';
import { ResponseErrorType } from '../common';
import { setCurrentCountry } from '../me/shared-action-creators';
import {
  CreateReviewRequest,
  DoItForMeActionFailure,
  DoItForMeRequest,
  DoItForMeState,
  DoItForMeSubmissionPDFRequest,
  DoItForMeSubmissionPDFSuccess,
  DoItForMeSuccess,
  GetReviewRequest,
  IdApprovedRequest,
  ReviewResponseSuccess,
  UpdateReviewRequest,
} from './types';

const initialState: DoItForMeState = {
  loading: false,
  error: undefined,
  id: 0,
  userId: 0,
  year: Years['2k20'],
  reviewProviderId: 1,
  reviewProviderName: '',
  countryCode: CountryCodes.DE,
  status: DoItForMeStatus.OptedIn,
  statusLoading: false,
  metadata: {},
  submissionPDF: '',
  review: undefined,
  updateReviewSuccess: false,
  createReviewSuccess: false,
  reviewLoading: false,
  reviewError: undefined,
  overviewSeen: {},
};

const doItForMeSlice = createSlice({
  name: 'doItForMe',
  initialState,
  reducers: {
    doItForMeRequest: (
      state,
      action: PayloadAction<DoItForMeRequest>,
    ): DoItForMeState => ({
      ...state,
      loading: true,
      submissionPDF: action.payload.id !== state.id ? '' : state.submissionPDF,
    }),
    doItForMeCurrentsubmissionRequest: (state, _action): DoItForMeState => ({
      ...state,
      loading: true,
    }),
    doItForMeSuccess: (
      state,
      action: PayloadAction<DoItForMeSuccess>,
    ): DoItForMeState => ({
      ...state,
      ...action.payload,
      loading: false,
    }),
    doItForMeFailure: (
      state,
      action: PayloadAction<DoItForMeActionFailure>,
    ): DoItForMeState => ({
      ...state,
      loading: false,
      error:
        action.payload?.error ||
        intlStringsRegistry.getMessage('generic_error'),
    }),
    doItForMeNextStatus: (state, _) => ({
      ...state,
      statusLoading: true,
    }),
    doItForMeStatusChangeSuccess: (state, action) => ({
      ...state,
      status: action.payload.status,
      statusLoading: false,
    }),
    doItForMeStatusChangeFailure: (state, action) => ({
      ...state,
      statusLoading: false,
      status: action.payload.status,
    }),
    doItForMeProviderSuccess: (state, action) => ({
      ...state,
      reviewProviderName: action.payload,
    }),
    doItForMeProviderFailure: (state, _) => ({
      ...state,
      reviewProviderName: '',
    }),
    doItForMeSaveRequest: (state, _) => ({
      ...state,
      saveLoading: true,
    }),
    doItForMeSaveSuccess: (state, _) => ({
      ...state,
      saveLoading: false,
    }),
    doItForMeSaveFailure: (state, _) => ({
      ...state,
      saveLoading: false,
    }),
    doItForMeSubmissionUpdateRequest: (_): void => {},
    doItForMeSubmissionPDFRequest: (
      state,
      _action: PayloadAction<DoItForMeSubmissionPDFRequest>,
    ): DoItForMeState => ({
      ...state,
      submissionPDFLoading: true,
    }),
    doItForMeSubmissionPDFSuccess: (
      state,
      action: PayloadAction<DoItForMeSubmissionPDFSuccess>,
    ): DoItForMeState => ({
      ...state,
      submissionPDF: action.payload,
      submissionPDFLoading: false,
    }),
    doItForMeSubmissionPDFFailure: (state): DoItForMeState => ({
      ...state,
      submissionPDF: undefined,
      submissionPDFLoading: false,
    }),
    doItForMeSubmissionCommentsRequest: (state, _) => ({ ...state }),
    doItForMeReviewRequest: (
      state,
      _action: PayloadAction<GetReviewRequest>,
    ): DoItForMeState => ({
      ...state,
      review: undefined,
      reviewLoading: true,
    }),
    doItForMeReviewSuccess: (
      state,
      action: PayloadAction<ReviewResponseSuccess>,
    ): DoItForMeState => ({
      ...state,
      review: action.payload,
      reviewLoading: false,
      reviewError: undefined,
    }),
    doItForMeReviewFailure: (
      state,
      action: PayloadAction<ResponseErrorType>,
    ): DoItForMeState => ({
      ...state,
      review: undefined,
      reviewLoading: false,
      reviewError: action.payload,
    }),
    doItForMeCreateReviewRequest: (
      state,
      _action: PayloadAction<CreateReviewRequest>,
    ): DoItForMeState => ({
      ...state,
      reviewLoading: true,
      createReviewSuccess: false,
    }),
    doItForMeCreateReviewSuccess: (
      state,
      action: PayloadAction<ReviewResponseSuccess>,
    ): DoItForMeState => ({
      ...state,
      review: { ...action.payload },
      createReviewSuccess: true,
      reviewLoading: false,
      reviewError: undefined,
    }),
    doItForMeCreateReviewFailure: (
      state,
      action: PayloadAction<ResponseErrorType>,
    ): DoItForMeState => ({
      ...state,
      review: undefined,
      reviewLoading: false,
      reviewError: action.payload,
    }),
    doItForMeUpdateReviewRequest: (
      state,
      _action: PayloadAction<UpdateReviewRequest>,
    ): DoItForMeState => ({
      ...state,
      reviewLoading: true,
      updateReviewSuccess: false,
    }),
    doItForMeUpdateReviewSuccess: (
      state,
      action: PayloadAction<ReviewResponseSuccess>,
    ): DoItForMeState => ({
      ...state,
      review: { ...state.review, ...action.payload },
      updateReviewSuccess: true,
      reviewLoading: false,
      reviewError: undefined,
    }),
    doItForMeUpdateReviewFailure: (
      state,
      action: PayloadAction<ResponseErrorType>,
    ): DoItForMeState => ({
      ...state,
      review: undefined,
      reviewLoading: false,
      reviewError: action.payload,
    }),
    doItForMeAutoUpdateToPreparation: (state): DoItForMeState => ({
      ...state,
    }),
    doItForMeOverviewSeenInit: (state): DoItForMeState => ({
      ...state,
      overviewSeen: {
        ...state.overviewSeen,
      },
    }),
    doItForMeOverviewSeenSet: (
      state,
      action: PayloadAction<{ seen: boolean }>,
    ): DoItForMeState => ({
      ...state,
      overviewSeen: {
        ...state.overviewSeen,
        [state.id]: action.payload.seen,
      },
    }),
  },
  extraReducers: builder =>
    builder
      .addCase(setCurrentCountry, () => initialState)
      .addDefaultCase(state => state),
});

export default doItForMeSlice;

// Actions
export const {
  doItForMeRequest,
  doItForMeCurrentsubmissionRequest,
  doItForMeSuccess,
  doItForMeFailure,
  doItForMeNextStatus,
  doItForMeStatusChangeSuccess,
  doItForMeStatusChangeFailure,
  doItForMeProviderSuccess,
  doItForMeProviderFailure,
  doItForMeSaveRequest,
  doItForMeSaveSuccess,
  doItForMeSaveFailure,
  doItForMeSubmissionPDFRequest,
  doItForMeSubmissionPDFSuccess,
  doItForMeSubmissionPDFFailure,
  doItForMeSubmissionCommentsRequest,
  doItForMeReviewRequest,
  doItForMeReviewSuccess,
  doItForMeReviewFailure,
  doItForMeCreateReviewRequest,
  doItForMeCreateReviewSuccess,
  doItForMeCreateReviewFailure,
  doItForMeUpdateReviewRequest,
  doItForMeUpdateReviewSuccess,
  doItForMeUpdateReviewFailure,
  doItForMeAutoUpdateToPreparation,
  doItForMeOverviewSeenInit,
  doItForMeOverviewSeenSet,
} = doItForMeSlice.actions;

export const approvedIdentificationDoItForMeRequest =
  createAction<IdApprovedRequest>(
    'difm/approvedIdentificationDoItForMeRequest',
  );

export const doItForMeSubmissionUpdateRequest = createAction<
  { retry?: boolean } | undefined
>('difm/doItForMeSubmissionUpdateRequest');
