import { PayloadAction, createAction, createSlice } from '@reduxjs/toolkit';

import { SERVICE_TYPES } from '@taxfix/operations-types';
import { SubmitResponse } from '@taxfix/submissions-sdk/dist/submit/submit';
import { ValidateResponse } from '@taxfix/submissions-sdk/dist/validate/validate';
import {
  States,
  SubmissionRelationsIdentificationSource,
  SubmissionResultWithId,
} from '@taxfix/submissions-types';
import { ReviewStatesKey } from '@taxfix/tax-authority-es-sdk/dist/expert-review/@types';
import { ReviewStatesKey as NLReviewStatesKey } from '@taxfix/tax-authority-nl-sdk/dist/expert-review/@types';
import { UserCategoryState } from '@taxfix/tax-authority-nl-sdk/dist/user-details/@types';
import { CountryCodes, Documents, Years } from '@taxfix/types';

import { Prefills } from '../../components/submission/es/prefilled-data/types';
import { intlStringsRegistry } from '../../intl';
import { ResponseErrorType } from '../common';
import { setCurrentCountry } from '../me';
import { SubmissionWithId } from '../submissions';
import {
  DeviationPredictionFailure,
  DeviationPredictionSuccessResponse,
  NLPdfSubmissionRequest,
  OneResultRequest,
  RetrySpainSubmissionRequest,
  SaveNotes,
  SaveNotesRequest,
  SaveNotesStatus,
  SpainExportXMLSubmissionRequest,
  SpainHaciendaDraft,
  SpainImportSubmissionPDFRequest,
  SpainImportXMLSubmissionRequest,
  SpainPrefillAnswers,
  SpainPreviewPdfSubmissionRequest,
  SubmissionActionFailure,
  SubmissionAddAdditional,
  SubmissionAddAdditionalDe,
  SubmissionAddAdditionalEs,
  SubmissionAddAdditionalIt,
  SubmissionAddFraudLabelRequest,
  SubmissionBlockedSuccess,
  SubmissionByDoItForMeRequest,
  SubmissionByExpertReviewRequest,
  SubmissionDeleteRequest,
  SubmissionDocumentUploadRequest,
  SubmissionDocumentUploadSuccess,
  SubmissionModifyOverride,
  SubmissionRequest,
  SubmissionRevertRequest,
  SubmissionSaveRequest,
  SubmissionState,
} from './types';
import { modifyOverrides } from './utils';

const initialState: SubmissionState = {
  id: 0,
  userId: 0,
  state: States.Created,
  identificationDocumentId: 0,
  identificationDocumentState: Documents.States.Created,
  identificationSource: SubmissionRelationsIdentificationSource.Document,
  created: new Date(),
  updated: new Date(),
  year: Years['2k19'],
  type: 0,
  data: [],
  taxForm: '',
  appData: {},
  countryCode: CountryCodes.DE,
  overrides: { overrides: [], additionalFields: [], additionalOverrides: [] },
  attempt: 0,
  calculationResult: {
    amount: 0,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    break_down: {},
    currency: '',
    position: 'refund',
  },
  deleted: null,
  failedReason: '',
  isAutomatedSubmission: false,
  isForced: false,
  //   isIdentified: false,
  isPrefilled: false,
  isReviewRequired: false,
  metadata: {},
  notes: '',
  paymentId: 0,
  paymentStatus: undefined,
  plausibilityOutput: '',
  readyAt: null,
  submittedAt: null,
  systemVersion: 1,
  validationOutput: {
    message: '',
    details: '',
    metadata: {},
  },
  validationResult: false,
  validationWarnings: null,
  version: 0,
  loading: true,
  uploadingDocument: false,
  error: undefined,
  results: undefined,
  modified: false,
  archivedAt: null,
  retries: 0,
  deletionReasonMessage: '',
  deviation: {},
  importing: false,
  serviceType: undefined,
  spain: {
    xmlSubmission: '',
    exportXMLLoading: false,
    importXMLLoading: false,
    importPDFLoading: false,
    submissionLoading: false,
    prefill: '',
    isDownloadPrefillLoading: false,
    isUserDetailsRequestLoading: false,
    isExpertReviewStatusLoading: false,
    isUpdatingExpertReviewStatusLoading: false,
    isDeclarationPdfLoading: false,
    expertReviewStatus: ReviewStatesKey.InProgress,
    declarationPdf: '',
    prefillAnswers: {},
    haciendaDraft: [],
    isDraftLoading: false,
    isPrefillAnswersLoading: false,
    refNum: '',
    isPrefillDataLoading: false,
    isM100PreviewLoading: false,
    m100Preview: null,
    prefillData: [],
  },
  netherlands: {
    isUserDetailsRequestLoading: false,
    isExpertReviewStatusLoading: false,
    isUpdatingExpertReviewStatusLoading: false,
    isExpertReviewRefundAmountLoading: true,
    expertReviewRefundAmount: undefined,
    expertReviewStatus: NLReviewStatesKey.InProgress,
    refNum: '',
    bsn: '',
    userDetails: undefined,
    partnerDetails: undefined,
    rejection: undefined,
    prefillMethod: '',
    partnerACode: '',
    partnerBCode: '',
    importPDFLoading: false,
    isUpdatingCategoryState: false,
    categoryState: undefined,
    isCategoryStateRequestLoading: false,
  },
  coldArchivedAt: null,
  australia: {},
  labelledAsFraud: false,
};

const submissionSlice = createSlice({
  name: 'submission',
  initialState: initialState,
  reducers: {
    submissionModifyNotesRequest: (
      state,
      _: PayloadAction<SaveNotesRequest>,
    ): void => {
      state.error = initialState.error;
    },
    submissionModifyNotesSuccess: (
      state,
      action: PayloadAction<SaveNotes>,
    ): void => {
      state.notes = action.payload.notes;
    },
    submissionModifyNotesFailure: (
      state,
      action: PayloadAction<SaveNotesStatus>,
    ): void => {
      state.error =
        action.payload?.error ||
        intlStringsRegistry.getMessage('generic_error');
    },
    submissionByExpertReviewRequest: (
      state,
      _: PayloadAction<SubmissionByExpertReviewRequest>,
    ): void => {
      Object.assign(state, initialState);
      state.loading = true;
      state.error = initialState.error;
      state.retries = initialState.retries;
    },
    submissionByDoItForMeRequest: (
      state,
      _: PayloadAction<SubmissionByDoItForMeRequest>,
    ): void => {
      Object.assign(state, initialState);
      state.loading = true;
      state.error = initialState.error;
      state.retries = initialState.retries;
    },
    submissionRequest: (state, _: PayloadAction<SubmissionRequest>): void => {
      Object.assign(state, initialState);
      state.loading = true;
      state.error = initialState.error;
      state.retries = initialState.retries;
    },
    submissionSuccess: (
      state,
      { payload: { overrides, ...payload } }: PayloadAction<SubmissionWithId>,
    ): SubmissionState => ({
      ...state,
      ...payload,
      overrides: {
        overrides: overrides?.overrides?.length
          ? overrides.overrides
          : payload.data?.map(() => ({ override: false, deleted: false })),
        additionalFields: overrides?.additionalFields || [],
        additionalOverrides: overrides?.additionalOverrides || [],
      },
      loading: false,
      error: initialState.error,
      updated: payload.updated as Date,
    }),
    submissionRequestFailure: (
      state,
      action: PayloadAction<SubmissionActionFailure>,
    ): SubmissionState => ({
      ...state,
      loading: false,
      error:
        action.payload?.error ||
        intlStringsRegistry.getMessage('generic_error'),
    }),
    submissionRequestFinished: (state): SubmissionState => ({
      ...state,
      loading: false,
    }),
    submissionModifyOverride: (
      state,
      {
        payload: { originalIndex, isAdditionalField, override },
      }: PayloadAction<SubmissionModifyOverride>,
    ): void => {
      modifyOverrides(state, [{ originalIndex, isAdditionalField, override }]);

      state.modified = true;
    },
    submissionModifyOverrides: (
      state,
      { payload }: PayloadAction<SubmissionModifyOverride[]>,
    ): void => {
      modifyOverrides(state, payload);

      if (payload.length) {
        state.modified = true;
      }
    },
    submissionAddAdditional: (
      state,
      { payload }: PayloadAction<SubmissionAddAdditional>,
    ): void => {
      if (state.countryCode === CountryCodes.DE) {
        const payloadDe = payload as SubmissionAddAdditionalDe;
        state.overrides.additionalFields.push({
          coordinate: {
            id: payloadDe.id,
            index: payloadDe.index ?? 1,
            lfdNr: payloadDe.lfdNr ?? 1,
          },
          value: payloadDe.value,
        });
        state.overrides.additionalOverrides.push({
          deleted: false,
          override: false,
        });
        state.modified = true;
      }

      if (state.countryCode === CountryCodes.IT) {
        const payloadIt = payload as SubmissionAddAdditionalIt;
        state.overrides.additionalFields.push({
          coordinate: {
            id: payloadIt.id,
            indexes: payloadIt.indexes,
          },
          value: payloadIt.value,
        });
        state.overrides.additionalOverrides.push({
          deleted: false,
          override: false,
        });
        state.modified = true;
      }

      if (state.countryCode === CountryCodes.ES) {
        const payloadEs = payload as SubmissionAddAdditionalEs;
        state.overrides.additionalFields.push({
          coordinate: {
            id: payloadEs.id,
            index: payloadEs.index,
          },
          value: payloadEs.value,
        });
        state.overrides.additionalOverrides.push({
          deleted: false,
          override: false,
        });
        state.modified = true;
      }
    },
    submissionAddFieldsDE: (
      state,
      { payload }: PayloadAction<SubmissionAddAdditionalDe[]>,
    ): void => {
      if (state.countryCode === CountryCodes.DE) {
        payload.forEach(field => {
          state.overrides.additionalFields.push({
            coordinate: {
              id: field.id,
              index: field.index ?? 1,
              lfdNr: field.lfdNr ?? 1,
            },
            value: field.value,
          });
          state.overrides.additionalOverrides.push({
            deleted: false,
            override: false,
          });
          state.modified = true;
        });
      }
    },
    submissionSaveRequest: (
      state,
      _: PayloadAction<SubmissionSaveRequest>,
    ): SubmissionState => ({
      ...state,
      error: initialState.error,
    }),
    submissionBlockRequest: (
      state,
      _: PayloadAction<SubmissionRequest>,
    ): SubmissionState => ({
      ...state,
      error: initialState.error,
    }),
    submissionAddFraudLabelRequest: (
      state,
      _: PayloadAction<SubmissionAddFraudLabelRequest>,
    ): SubmissionState => ({
      ...state,
      error: initialState.error,
    }),
    submissionFraudLabelRequest: (
      state,
      _: PayloadAction<SubmissionRequest>,
    ): SubmissionState => ({
      ...state,
      labelledAsFraud: false,
      error: initialState.error,
    }),
    submissionArchiveRequest: (
      state,
      _: PayloadAction<SubmissionRequest>,
    ): SubmissionState => ({
      ...state,
      error: initialState.error,
    }),
    submissionUnarchiveRequest: (
      state,
      _: PayloadAction<SubmissionRequest>,
    ): SubmissionState => ({
      ...state,
      error: initialState.error,
    }),
    submissionSaveSuccess: (
      state,
      action: PayloadAction<ValidateResponse>,
    ): SubmissionState => ({
      ...state,
      id: action.payload.id,
      state: action.payload.state as unknown as SubmissionState['state'],
      loading: false,
      modified: false,
    }),
    submissionSubmitRequest: (
      state,
      _: PayloadAction<SubmissionRequest>,
    ): SubmissionState => ({
      ...state,
      loading: true,
      error: initialState.error,
    }),
    submissionSubmitSuccess: (
      state,
      { payload }: PayloadAction<SubmitResponse>,
    ): SubmissionState => ({
      ...state,
      ...payload,
      loading: false,
    }),
    submissionArchiveSuccess: (state): void => {
      state.archivedAt = new Date();
    },
    submissionDeleteRequest: (
      state,
      _: PayloadAction<SubmissionDeleteRequest>,
    ): SubmissionState => ({
      ...state,
      loading: true,
      error: initialState.error,
    }),
    submissionDeleteSuccess: (state): SubmissionState => ({
      ...state,
      loading: false,
      state: States.Deleted,
    }),
    submissionResultSuccess: (
      state,
      { payload }: PayloadAction<SubmissionResultWithId[]>,
    ): SubmissionState => ({
      ...state,
      results: payload,
    }),
    submissionBlockSuccess: (
      state,
      { payload }: PayloadAction<SubmissionBlockedSuccess>,
    ): SubmissionState => ({
      ...state,
      ...payload,
      loading: false,
    }),
    submissionAddFraudLabelSuccess: (
      state,
      { payload }: PayloadAction<{ isFraud: boolean }>,
    ): SubmissionState => ({
      ...state,
      labelledAsFraud: payload.isFraud,
      loading: false,
    }),
    submissionFraudLabelSuccess: (
      state,
      { payload }: PayloadAction<{ isFraud?: boolean }>,
    ): SubmissionState => ({
      ...state,
      labelledAsFraud: Boolean(payload?.isFraud),
      loading: false,
    }),
    submissionUpdateRequest: (
      state,
      {
        payload: { retry } = { retry: false },
      }: PayloadAction<{ retry?: boolean } | undefined>,
    ): void => {
      state.error = initialState.error;
      if (retry) {
        state.retries++;
      } else {
        state.retries = initialState.retries;
      }
    },
    submissionUpdate: (
      state,
      { payload }: PayloadAction<Partial<SubmissionWithId>>,
    ): void => {
      Object.assign(state, payload);
    },
    submissionDocumentUploadRequest: (
      state,
      _: PayloadAction<SubmissionDocumentUploadRequest>,
    ): void => {
      state.uploadingDocument = true;
    },
    submissionDocumentUploadSuccess: (
      state,
      { payload }: PayloadAction<SubmissionDocumentUploadSuccess>,
    ): void => {
      state.identificationDocumentId = payload.id;
      state.identificationDocumentState = Documents.States.Created;
      state.uploadingDocument = false;
    },
    submissionPoaIdUploadRequest: (
      state,
      _: PayloadAction<SubmissionDocumentUploadRequest>,
    ): void => {
      state.uploadingDocument = true;
    },
    submissionPoaIdUploadSuccess: (
      state,
      { payload }: PayloadAction<{ id: number }>,
    ): void => {
      state.digitalPrefill = {
        poaId: payload.id,
        poaIdState: Documents.States.Created,
      };
      state.uploadingDocument = false;
    },
    submissionDocumentUploadStop: (state): void => {
      state.uploadingDocument = false;
    },
    deviationPredictionRequest: (
      state,
      { payload }: PayloadAction<SubmissionRequest>,
    ): void => {
      if (state.id === payload.id) {
        state.deviation = { loading: true };
      }
    },
    deviationPredictionSuccess: (
      state,
      { payload }: PayloadAction<DeviationPredictionSuccessResponse>,
    ): void => {
      if (state.id === payload.id) {
        state.deviation = {
          predictive: payload.predictive,
          loading: false,
        };
      }
    },
    deviationPredictionFailure: (
      state,
      { payload }: PayloadAction<DeviationPredictionFailure>,
    ): void => {
      if (state.id === payload.id) {
        state.deviation = { loading: false, error: payload.error };
      }
    },
    submissionUploadExternalCalculation: (
      state,
      _action: { payload: { file: File } },
    ): void => {
      state.importing = true;
    },
    submissionUploadExternalCalculationSuccess: (
      state,
      { payload }: PayloadAction<Partial<SubmissionWithId>>,
    ): void => {
      state.overrides = state.overrides || {};
      state.overrides.overrides = payload.overrides?.overrides || [];
      state.overrides.additionalFields =
        payload.overrides?.additionalFields || [];
      state.overrides.additionalOverrides =
        payload.overrides?.additionalOverrides || [];
      state.error = undefined;
      state.isForced = true;
      state.importing = false;
      state.modified = true;
    },
    submissionUploadExternalCalculationFailure: (state): void => {
      state.importing = false;
    },
    newSubmissionRequest: (
      state,
      action: PayloadAction<SERVICE_TYPES | undefined>,
    ): void => {
      state.loading = true;
      state.serviceType = action.payload;
    },
    newSubmissionSuccess: (state): void => {
      state.loading = false;
      state.error = initialState.error;
    },
    newSubmissionFailure: (
      state,
      action: PayloadAction<ResponseErrorType | null>,
    ): void => {
      state.loading = false;
      state.error = action.payload;
    },
    retrySpainSubmissionRequest: (
      state,
      _: PayloadAction<RetrySpainSubmissionRequest>,
    ): SubmissionState => ({
      ...state,
      spain: {
        ...state.spain,
        submissionLoading: true,
      },
    }),
    retrySpainSubmissionSuccess: (state): SubmissionState => ({
      ...state,
      state: States.SubmitInProgress,
      spain: {
        ...state.spain,
        submissionLoading: false,
      },
    }),
    retrySpainSubmissionFailed: (state): SubmissionState => ({
      ...state,
      state: States.SubmissionFailed,
      loading: false,
    }),
    importSpainXMLSubmissionRequest: (
      state,
      _: PayloadAction<SpainImportXMLSubmissionRequest>,
    ): void => {
      state.spain.importXMLLoading = true;
    },
    importSpainXMLSubmissionSuccess: (state): void => {
      state.spain.importXMLLoading = false;
      state.spain.expertReviewStatus = ReviewStatesKey.Validated;
    },
    importSpainXMLSubmissionFailed: (state): void => {
      state.spain.importXMLLoading = false;
    },
    importSpainSubmissionPdfRequest: (
      state,
      _: PayloadAction<SpainImportSubmissionPDFRequest>,
    ): void => {
      state.spain.importPDFLoading = true;
    },
    importSpainSubmissionPdfSuccess: (state): void => {
      state.spain.importPDFLoading = false;
      state.state = States.Submitted;
    },
    importSpainSubmissionPdfFailed: (state): void => {
      state.spain.importPDFLoading = false;
    },
    exportSpainXMLSubmissionRequest: (
      state,
      _: PayloadAction<SpainExportXMLSubmissionRequest>,
    ): void => {
      state.spain.exportXMLLoading = true;
    },
    exportSpainXMLSubmissionSuccess: (
      state,
      { payload }: PayloadAction<string>,
    ): SubmissionState => ({
      ...state,
      spain: {
        ...state.spain,
        exportXMLLoading: false,
        xmlSubmission: payload,
      },
    }),
    exportSpainXMLSubmissionFailed: (state): void => {
      state.spain.exportXMLLoading = false;
    },
    exportSpainXMLSubmissionClear: (state): void => {
      state.spain.xmlSubmission = '';
    },
    downloadSpainPrefillRawRequest: (
      state,
      _: PayloadAction<{ fileType: 'csv' | 'raw' | 'json'; year: number }>,
    ): void => {
      state.spain.isDownloadPrefillLoading = true;
    },
    downloadSpainPrefillSucceeded: (
      state,
      { payload }: PayloadAction<string>,
    ): SubmissionState => ({
      ...state,
      spain: {
        ...state.spain,
        isDownloadPrefillLoading: false,
        prefill: payload,
      },
    }),
    downloadSpainPrefillFailed: (state): void => {
      state.spain.isDownloadPrefillLoading = false;
    },
    downloadSpainPrefillClear: (state): void => {
      state.spain.prefill = '';
    },
    getUserRefNumberRequest: (
      state,
      _: PayloadAction<{ userId: number }>,
    ): void => {
      state.spain.isUserDetailsRequestLoading = true;
    },
    getUserRefNumberRequestSuccess: (
      state,
      { payload }: PayloadAction<string>,
    ): SubmissionState => ({
      ...state,
      spain: {
        ...state.spain,
        isUserDetailsRequestLoading: false,
        refNum: payload,
      },
    }),
    getUserRefNumberRequestFailed: (state): void => {
      state.spain.isUserDetailsRequestLoading = false;
    },
    getSpainExpertReviewStatusRequest: (
      state,
      _: PayloadAction<{ submissionId: number }>,
    ): void => {
      state.spain.isExpertReviewStatusLoading = true;
    },
    getSpainExpertReviewStatusSuccess: (
      state,
      { payload }: PayloadAction<ReviewStatesKey>,
    ): SubmissionState => ({
      ...state,
      spain: {
        ...state.spain,
        isExpertReviewStatusLoading: false,
        expertReviewStatus: payload,
      },
    }),
    getSpainExpertReviewStatusFailed: (state): void => {
      state.spain.isExpertReviewStatusLoading = false;
    },
    getSpainPrefillAnswersRequest: (
      state,
      _: PayloadAction<{ userId: number; year: number }>,
    ): void => {
      state.spain.isPrefillAnswersLoading = true;
    },
    getSpainPrefillAnswersSuccess: (
      state,
      { payload }: PayloadAction<SpainPrefillAnswers>,
    ): SubmissionState => ({
      ...state,
      spain: {
        ...state.spain,
        isPrefillAnswersLoading: false,
        prefillAnswers: payload,
      },
    }),
    getSpainPrefillAnswersFailed: (state): void => {
      state.spain.isPrefillAnswersLoading = false;
    },
    getSpainDraftRequest: (
      state,
      _: PayloadAction<{ userId: number; year: number }>,
    ): void => {
      state.spain.isDraftLoading = true;
    },
    getSpainDraftSuccess: (
      state,
      { payload }: PayloadAction<SpainHaciendaDraft>,
    ): SubmissionState => ({
      ...state,
      spain: {
        ...state.spain,
        isDraftLoading: false,
        haciendaDraft: payload,
      },
    }),
    getSpainDraftFailed: (state): void => {
      state.spain.isDraftLoading = false;
    },
    updateSpainExpertReviewStatusRequest: (
      state,
      _: PayloadAction<{ submissionId: number; newState: ReviewStatesKey }>,
    ): void => {
      state.spain.isUpdatingExpertReviewStatusLoading = true;
    },
    updateSpainExpertReviewStatusSuccess: (
      state,
      { payload }: PayloadAction<ReviewStatesKey>,
    ): SubmissionState => ({
      ...state,
      spain: {
        ...state.spain,
        isUpdatingExpertReviewStatusLoading: false,
        expertReviewStatus: payload,
      },
    }),
    updateSpainExpertReviewStatusFailed: (state): void => {
      state.spain.isUpdatingExpertReviewStatusLoading = false;
    },
    submissionSpainDeclarationPdfRequest: (
      state,
      _: PayloadAction<SpainPreviewPdfSubmissionRequest>,
    ): void => {
      state.spain.isDeclarationPdfLoading = true;
    },
    submissionSpainDeclarationPdfSuccess: (
      state,
      { payload }: PayloadAction<string>,
    ): SubmissionState => ({
      ...state,
      spain: {
        ...state.spain,
        isDeclarationPdfLoading: false,
        declarationPdf: payload,
      },
    }),
    submissionSpainDeclarationPdfFailed: (state): void => {
      state.spain.isDeclarationPdfLoading = false;
    },
    submissionSpainDeclarationPdfClear: (state): void => {
      state.spain.declarationPdf = '';
    },
    submissionSetServiceType: (
      state,
      { payload }: PayloadAction<{ serviceType: SERVICE_TYPES | undefined }>,
    ): SubmissionState => ({
      ...state,
      serviceType: payload.serviceType,
    }),
    submissionClearServiceType: (state): SubmissionState => ({
      ...state,
      serviceType: undefined,
    }),
    getSpainPrefillData: (
      state,
      _: PayloadAction<{ fileType: 'json'; year: number }>,
    ): void => {
      state.spain.isPrefillDataLoading = true;
    },
    getSpainPrefillDataSucceeded: (
      state,
      { payload }: PayloadAction<Array<Prefills>>,
    ): SubmissionState => ({
      ...state,
      spain: {
        ...state.spain,
        isPrefillDataLoading: false,
        prefillData: payload,
      },
    }),
    getSpainPrefillDataFailed: (state): void => {
      state.spain.isPrefillDataLoading = false;
    },
    getSpainM100PreviewRequest: (
      state,
      _: PayloadAction<SpainPreviewPdfSubmissionRequest>,
    ): void => {
      state.spain.isM100PreviewLoading = true;
    },
    getSpainM100PreviewSuccess: (
      state,
      { payload }: PayloadAction<string>,
    ): SubmissionState => ({
      ...state,
      spain: {
        ...state.spain,
        isM100PreviewLoading: false,
        m100Preview: payload,
      },
    }),
    getSpainM100PreviewFailed: (state): void => {
      state.spain.isM100PreviewLoading = false;
    },

    getNLUserDetailRequest: (
      state,
      _: PayloadAction<{ userId: number }>,
    ): void => {
      state.netherlands.isUserDetailsRequestLoading = true;
    },
    getNLUserDetailRequestSuccess: (
      state,
      { payload }: PayloadAction<any>,
    ): SubmissionState => ({
      ...state,
      netherlands: {
        ...state.netherlands,
        isUserDetailsRequestLoading: false,
        ...payload,
      },
    }),
    getNLUserDetailRequestFailed: (state): void => {
      state.netherlands.isUserDetailsRequestLoading = false;
    },
    getNLExpertReviewStatusRequest: (
      state,
      _: PayloadAction<{ submissionId: number }>,
    ): void => {
      state.netherlands.isExpertReviewStatusLoading = true;
    },
    getNLExpertReviewStatusSuccess: (
      state,
      { payload }: PayloadAction<NLReviewStatesKey>,
    ): SubmissionState => ({
      ...state,
      netherlands: {
        ...state.netherlands,
        isExpertReviewStatusLoading: false,
        expertReviewStatus: payload,
      },
    }),
    getNLExpertReviewStatusFailed: (state): void => {
      state.netherlands.isExpertReviewStatusLoading = false;
    },
    getNLExpertReviewRefundAmountRequest: (
      state,
      _: PayloadAction<{ submissionId: number }>,
    ): void => {
      state.netherlands.isExpertReviewRefundAmountLoading = true;
    },
    getNLExpertReviewRefundAmountSuccess: (
      state,
      { payload }: PayloadAction<number>,
    ): SubmissionState => ({
      ...state,
      netherlands: {
        ...state.netherlands,
        isExpertReviewRefundAmountLoading: false,
        expertReviewRefundAmount: payload,
      },
    }),
    getNLExpertReviewRefundAmountFailed: (state): void => {
      state.netherlands.isExpertReviewRefundAmountLoading = false;
    },
    updateNLExpertReviewRefundAmountRequest: (
      state,
      _: PayloadAction<{ submissionId: number; newState: number }>,
    ): void => {
      state.netherlands.isExpertReviewRefundAmountLoading = true;
    },
    updateNLExpertReviewRefundAmountSuccess: (
      state,
      { payload }: PayloadAction<number>,
    ): SubmissionState => ({
      ...state,
      netherlands: {
        ...state.netherlands,
        isExpertReviewRefundAmountLoading: false,
        expertReviewRefundAmount: payload,
      },
    }),
    updateNLExpertReviewRefundAmountFailed: (state): void => {
      state.netherlands.isExpertReviewRefundAmountLoading = false;
    },
    updateNLExpertReviewStatusRequest: (
      state,
      _: PayloadAction<{ submissionId: number; newState: NLReviewStatesKey }>,
    ): void => {
      state.netherlands.isUpdatingExpertReviewStatusLoading = true;
    },
    updateNLExpertReviewStatusSuccess: (
      state,
      { payload }: PayloadAction<NLReviewStatesKey>,
    ): SubmissionState => ({
      ...state,
      netherlands: {
        ...state.netherlands,
        isUpdatingExpertReviewStatusLoading: false,
        expertReviewStatus: payload,
      },
    }),
    updateNLExpertReviewStatusFailed: (state): void => {
      state.netherlands.isUpdatingExpertReviewStatusLoading = false;
    },
    importNLSubmitPdfDeclarationRequest: (
      state,
      _: PayloadAction<NLPdfSubmissionRequest>,
    ): void => {
      state.netherlands.importPDFLoading = true;
    },
    importNLSubmitPdfDeclarationSuccess: (state): void => {
      state.netherlands.importPDFLoading = false;
      state.netherlands.expertReviewStatus = NLReviewStatesKey.DraftReady;
    },
    importNLSubmitPdfDeclarationFailed: (state): void => {
      state.spain.importPDFLoading = false;
    },
    updateNLUserCategoriesStateRequest: (
      state,
      _: PayloadAction<{
        userId: number;
        categoryState: UserCategoryState;
        submissionId: number;
      }>,
    ): void => {
      state.netherlands.isUpdatingCategoryState = true;
    },
    updateNLUserCategoriesStateSuccess: (
      state,
      { payload }: PayloadAction<UserCategoryState | undefined>,
    ): SubmissionState => ({
      ...state,
      netherlands: {
        ...state.netherlands,
        isUpdatingCategoryState: false,
        categoryState: payload,
      },
    }),
    updateNLUserCategoriesStateFailed: (state): void => {
      state.netherlands.isUpdatingCategoryState = false;
    },
    getNLUserCategoryStateRequest: (
      state,
      _: PayloadAction<{ userId: number }>,
    ): void => {
      state.netherlands.isCategoryStateRequestLoading = true;
    },
    getNLUserCategoryStateRequestSuccess: (
      state,
      { payload }: PayloadAction<UserCategoryState>,
    ): SubmissionState => ({
      ...state,
      netherlands: {
        ...state.netherlands,
        isCategoryStateRequestLoading: false,
        categoryState: payload,
      },
    }),
    getNLUserCategoryStateRequesttFailed: (state): void => {
      state.netherlands.isCategoryStateRequestLoading = false;
    },
    getSubmissionAustraliaDetailsRequest: (
      state,
      _: PayloadAction<{ userId: number; submissionId: number }>,
    ): void => {
      state.australia.loading = true;
    },
    getSubmissionAustraliaDetailsSuccess: (state, action): void => {
      const { australia } = state;
      state.australia = {
        ...australia,
        ...action.payload,
        loading: false,
        error: null,
      };
    },
    getSubmissionAustraliaDetailsFailure: (state, action): void => {
      const { australia } = state;
      state.australia = {
        ...australia,
        loading: false,
        error: action.payload,
      };
    },
    sendToUserAction: (
      state,
      _: PayloadAction<{
        submissionId: number;
        userId: number;
      }>,
    ): void => {
      state.australia.loading = true;
    },
    markAsFiledAction: (
      state,
      _: PayloadAction<{
        submissionId: number;
        userId: number;
      }>,
    ): void => {
      state.australia.loading = true;
    },
    markAsPendingAction: (
      state,
      _: PayloadAction<{
        submissionId: number;
        userId: number;
      }>,
    ): void => {
      state.australia.loading = true;
    },
    inputNeededAction: (
      state,
      _: PayloadAction<{
        submissionId: number;
        userId: number;
      }>,
    ): void => {
      state.australia.loading = true;
    },
    requestIdentificationAction: (
      state,
      _: PayloadAction<{
        submissionId: number;
        userId: number;
      }>,
    ): void => {
      state.australia.loading = true;
    },
    deleteAUSubmissionAction: (
      state,
      _: PayloadAction<{
        userId: number;
        submissionId: number;
      }>,
    ): void => {
      state.australia.loading = true;
    },
    approveIdentificationAction: (
      state,
      _: PayloadAction<{
        userId: number;
        submissionId: number;
      }>,
    ): void => {
      state.australia.loading = true;
    },
    rejectIdentificationAction: (
      state,
      _: PayloadAction<{
        userId: number;
        submissionId: number;
      }>,
    ): void => {
      state.australia.loading = true;
    },
    getAustraliaUserIdentificationRequest: (
      state,
      _: PayloadAction<{ userId: number }>,
    ): void => {
      state.australia.identityLoading = true;
    },
    getAustraliaUserIdentificationSuccess: (state, action): void => {
      const { australia } = state;
      state.australia = {
        ...australia,
        ...action.payload,
        identityLoading: false,
        error: null,
      };
    },
    getAustraliaUserIdentificationFailure: (state, action): void => {
      const { australia } = state;
      state.australia = {
        ...australia,
        document: undefined,
        identityLoading: false,
        error: action.payload,
      };
    },
  },

  extraReducers: builder =>
    builder.addCase(setCurrentCountry, () => initialState),
});

export default submissionSlice;

export const {
  sendToUserAction,
  markAsFiledAction,
  markAsPendingAction,
  inputNeededAction,
  requestIdentificationAction,
  getSubmissionAustraliaDetailsRequest,
  getSubmissionAustraliaDetailsSuccess,
  getSubmissionAustraliaDetailsFailure,
  submissionModifyNotesRequest,
  submissionModifyNotesSuccess,
  submissionModifyNotesFailure,
  submissionRequest,
  submissionSubmitRequest,
  submissionDeleteRequest,
  submissionSaveRequest,
  submissionArchiveRequest,
  submissionUnarchiveRequest,
  submissionSuccess,
  submissionRequestFinished,
  submissionRequestFailure,
  submissionModifyOverride,
  submissionModifyOverrides,
  submissionAddAdditional,
  submissionAddFieldsDE,
  submissionSaveSuccess,
  submissionSubmitSuccess,
  submissionDeleteSuccess,
  submissionResultSuccess,
  submissionUpdateRequest,
  submissionArchiveSuccess,
  submissionUpdate,
  submissionDocumentUploadRequest,
  submissionDocumentUploadSuccess,
  submissionPoaIdUploadRequest,
  submissionPoaIdUploadSuccess,
  submissionDocumentUploadStop,
  deviationPredictionRequest,
  deviationPredictionSuccess,
  deviationPredictionFailure,
  submissionUploadExternalCalculation,
  submissionUploadExternalCalculationSuccess,
  submissionUploadExternalCalculationFailure,
  newSubmissionRequest,
  newSubmissionSuccess,
  newSubmissionFailure,
  submissionByExpertReviewRequest,
  submissionByDoItForMeRequest,
  retrySpainSubmissionRequest,
  retrySpainSubmissionSuccess,
  retrySpainSubmissionFailed,
  importSpainXMLSubmissionRequest,
  importSpainXMLSubmissionSuccess,
  importSpainXMLSubmissionFailed,
  importSpainSubmissionPdfRequest,
  importSpainSubmissionPdfSuccess,
  importSpainSubmissionPdfFailed,
  exportSpainXMLSubmissionRequest,
  exportSpainXMLSubmissionSuccess,
  exportSpainXMLSubmissionFailed,
  exportSpainXMLSubmissionClear,
  downloadSpainPrefillRawRequest,
  downloadSpainPrefillSucceeded,
  downloadSpainPrefillFailed,
  downloadSpainPrefillClear,
  getUserRefNumberRequest,
  getUserRefNumberRequestSuccess,
  getUserRefNumberRequestFailed,
  getSpainExpertReviewStatusRequest,
  getSpainExpertReviewStatusSuccess,
  getSpainExpertReviewStatusFailed,
  updateSpainExpertReviewStatusRequest,
  updateSpainExpertReviewStatusSuccess,
  updateSpainExpertReviewStatusFailed,
  submissionSpainDeclarationPdfRequest,
  submissionSpainDeclarationPdfSuccess,
  submissionSpainDeclarationPdfFailed,
  submissionSpainDeclarationPdfClear,
  getSpainPrefillAnswersRequest,
  getSpainPrefillAnswersSuccess,
  getSpainPrefillAnswersFailed,
  getSpainDraftRequest,
  getSpainDraftSuccess,
  getSpainDraftFailed,
  submissionSetServiceType,
  submissionClearServiceType,
  getSpainPrefillData,
  getSpainPrefillDataSucceeded,
  getSpainPrefillDataFailed,
  getSpainM100PreviewRequest,
  getSpainM100PreviewSuccess,
  getSpainM100PreviewFailed,
  getNLUserDetailRequest,
  getNLUserDetailRequestSuccess,
  getNLUserDetailRequestFailed,
  getNLExpertReviewStatusRequest,
  getNLExpertReviewStatusSuccess,
  getNLExpertReviewStatusFailed,
  getNLExpertReviewRefundAmountRequest,
  getNLExpertReviewRefundAmountSuccess,
  getNLExpertReviewRefundAmountFailed,
  updateNLExpertReviewRefundAmountRequest,
  updateNLExpertReviewRefundAmountSuccess,
  updateNLExpertReviewRefundAmountFailed,
  updateNLExpertReviewStatusRequest,
  updateNLExpertReviewStatusSuccess,
  updateNLExpertReviewStatusFailed,
  importNLSubmitPdfDeclarationRequest,
  importNLSubmitPdfDeclarationSuccess,
  importNLSubmitPdfDeclarationFailed,
  updateNLUserCategoriesStateRequest,
  updateNLUserCategoriesStateSuccess,
  updateNLUserCategoriesStateFailed,
  getNLUserCategoryStateRequest,
  getNLUserCategoryStateRequestSuccess,
  getNLUserCategoryStateRequesttFailed,
  deleteAUSubmissionAction,
  approveIdentificationAction,
  rejectIdentificationAction,
  getAustraliaUserIdentificationRequest,
  getAustraliaUserIdentificationSuccess,
  getAustraliaUserIdentificationFailure,
  submissionBlockRequest,
  submissionBlockSuccess,
  submissionAddFraudLabelRequest,
  submissionAddFraudLabelSuccess,
  submissionFraudLabelRequest,
  submissionFraudLabelSuccess,
} = submissionSlice.actions;

export const submissionResultsRequest = createAction<SubmissionRequest>(
  'submission/submissionResultsRequest',
);

export const oneSubmissionResultRequest = createAction<OneResultRequest>(
  'submission/oneSubmissionResultRequest',
);

export const submissionResultPdfRequest = createAction<
  SubmissionRequest & { newWindow: Window | null }
>('submission/submissionResultPdfRequest');

export const submissionRevertRequest = createAction<SubmissionRevertRequest>(
  'submission/submissionRevertRequest',
);
